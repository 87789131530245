import { PestroutesReviewsSummary } from "../OverallReviews"
import { calculateRatingRatioPercentage } from "./calculateRatingRatioPercentage"
import { totalRatingsTemplate } from "./totalRatingsTemplate"

type Position = "STAR_1" | "STAR_2" | "STAR_3" | "STAR_4" | "STAR_5"

export const getTotalRatingsColumnMinWidth = (
  reviewsCount: number,
  statsReference: PestroutesReviewsSummary
) => {
  const ZERO_RATINGS = "0% (0)"
  let maxWidth = 0

  if (reviewsCount === 0) {
    const noReviewsStringSize = ZERO_RATINGS.length
    maxWidth = Math.max(maxWidth, noReviewsStringSize)
    return maxWidth
  }

  for (let ratingPosition = 1; ratingPosition < 6; ratingPosition++) {
    const position = `STAR_${ratingPosition}` as Position
    const countByRatingPosition = statsReference[position]
    const percentage = calculateRatingRatioPercentage(
      countByRatingPosition,
      reviewsCount
    )
    const totalRatings = totalRatingsTemplate(countByRatingPosition, percentage)
    const totalRatingsStringSize = totalRatings.length
    maxWidth = Math.max(maxWidth, totalRatingsStringSize)
  }
  return maxWidth
}
