export const calculateRatingRatioPercentage = (
  countByRatingPosition: number,
  reviewsCount: number
) => {
  if (reviewsCount === 0) return "0"
  const ratioPercentage = (countByRatingPosition * 100) / reviewsCount
  if (ratioPercentage >= 1) {
    return Math.floor(ratioPercentage).toFixed(1)
  } else {
    const ratioOneDecimal = ratioPercentage.toFixed(1)
    return ratioOneDecimal
  }
}
