import React from "react"
import type { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"
import "./parallax-background-image.module.scss"

interface ParallaxBackgroundImageProps {
  image: ImageBuilder
  loading?: "eager" | "lazy"
}

export const ParallaxBackgroundImage = ({
  image,
  loading = "eager",
}: ParallaxBackgroundImageProps) => {
  return (
    <div className="parallax-container">
      <Image
        alt={image?.description}
        className="parallax-container__background-image"
        image={image}
        objectFit="cover"
        loading={loading}
      />
    </div>
  )
}
