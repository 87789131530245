import React from "react"
import "./review-item.module.scss"
import { printStars } from "./utils/printStars"

function ReviewItem(r) {
  let review = {
    feedback: "",
    reviewer: "",
    city: "",
    state: "",
    zip: "",
    date: "",
    rating: "0",
  }
  if (r.review) review = r.review
  return (
    <div className={`review-item ${r?.className ?? ""}`}>
      <div className="review-item__details">
        <strong>{review.reviewer}</strong> | {review.city}, {review.state} {review.zip}
        <div className="review-item__feedback">{review.feedback}</div>
      </div>

      <div className="review-item__rating">
        <h2>{review.rating}</h2>
        <div className="review-item__stars">
          {printStars(Number(review.rating))}
        </div>
      </div>
    </div>
  )
}

export default ReviewItem
