import React, { useState, useEffect } from "react"
import { useExternalScripts } from "hooks/useExternalScripts"
import { getTotalRatingsColumnMinWidth } from "./utils/getTotalRatingsColumnMinWidth"
import { RatingBarChartRow } from "./RatingBarChartRow"
import { printStars } from "./utils/printStars"
import "./overall-reviews.module.scss"
import { SkeletonElement } from "components/skeleton/SkeletonElement"

const STAR_RATINGS = [5, 4, 3, 2, 1]
const SIZE_PX_ONE_DIGIT = 9
const NUMBER_OF_DIGITS_ZERO_REVIEWS = 6 //0% (0) = 6 digits (counting space between "%"" and "(")
const INITIAL_COLUMN_WIDTH_ZERO_REVIEWS_IN_PX =
  SIZE_PX_ONE_DIGIT * NUMBER_OF_DIGITS_ZERO_REVIEWS

type GetPestroutesClientReviews = () => void

export type PestroutesReviewsSummary = {
  STAR_5: number
  STAR_4: number
  STAR_3: number
  STAR_2: number
  STAR_1: number
  average: string
}

type PestroutesClientResponse = {
  success: boolean
  reviewsSummary: PestroutesReviewsSummary
}

const OverallReviews = () => {
  const [average, setAverage] = useState("0.00")
  const [stars, setStars] = useState("")
  const [starsStats, setStats] = useState<PestroutesReviewsSummary>()
  const [reviewsCount, setReviewsCount] = useState(0)
  const [ratingsColumnWidthSize, setRatingsColumnWidthSize] = useState(
    INITIAL_COLUMN_WIDTH_ZERO_REVIEWS_IN_PX
  )

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useExternalScripts()

  /* eslint-disable */
  const getPestroutesClientReviews: GetPestroutesClientReviews = async () => {
    const jQueryType = typeof (window as any).jQuery

    if (
      // @ts-ignore: PestroutesClient was delayed imported
      typeof PestroutesClient !== "object" ||
      jQueryType !== "function"
    ) {
      return setTimeout(getPestroutesClientReviews, 100)
    }

    let result: PestroutesClientResponse
    // @ts-ignore: PestroutesClient was delayed imported
    result = await PestroutesClient.getReviewsSummary()

    if (result.success) {
      setAverage(result.reviewsSummary.average)
      let stars = Math.round(Number(result.reviewsSummary.average))
      setStars(printStars(stars))
      setStats(result.reviewsSummary)
      let count = 0
      for (const [key, value] of Object.entries(result.reviewsSummary)) {
        if (key !== "average" && typeof value === "number") count += value
      }
      setReviewsCount(count)

      // We are calculating how wide the column with 0% (0) needs to be
      const charactersSize = getTotalRatingsColumnMinWidth(
        count,
        result.reviewsSummary
      )
      setRatingsColumnWidthSize(SIZE_PX_ONE_DIGIT * charactersSize)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    // @ts-ignore: avoids loading jquery before the LCP is done
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 200)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  /* eslint-disable */
  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])

  return (
    <div className="overall-reviews">
      <div className="u-container-header">
        <h2>Average Rating</h2>
      </div>
      <div className="overall-reviews-chart__wrapper">
        {!starsStats &&
          STAR_RATINGS.map(ratingPosition => (
            <SkeletonElement
              key={ratingPosition}
              type="text"
              modifier="full"
              style={{
                marginBottom: ratingPosition === 1 ? "0" : "0.395rem",
                height: "1.5625rem",
              }}
            />
          ))}

        {starsStats &&
          STAR_RATINGS.map(ratingPosition => (
            <RatingBarChartRow
              key={ratingPosition}
              ratingPosition={ratingPosition}
              reviewsCount={reviewsCount}
              starsStats={starsStats}
              ratingsColumnWidthSizePx={ratingsColumnWidthSize}
            />
          ))}
      </div>
      <div className="overall-reviews__wrapper">
        <h3>Overall Rating</h3>
        {average && (
          <div className="overall-reviews__average-rating">{average}</div>
        )}
        {stars && (
          <div className="overall-reviews__average-rating-stars">{stars}</div>
        )}
      </div>
    </div>
  )
}

export default OverallReviews
