import React from "react"
import { calculateRatingRatioPercentage } from "./utils/calculateRatingRatioPercentage"
import { totalRatingsTemplate } from "./utils/totalRatingsTemplate"
import "./rating-bar-chart.module.scss"
import { PestroutesReviewsSummary } from "./OverallReviews"

type Position = "STAR_1" | "STAR_2" | "STAR_3" | "STAR_4" | "STAR_5"

interface RatingBarChartRowProps {
  ratingPosition: number
  starsStats: PestroutesReviewsSummary
  reviewsCount: number
  ratingsColumnWidthSizePx: number
}

export const RatingBarChartRow = ({
  ratingPosition,
  starsStats,
  reviewsCount,
  ratingsColumnWidthSizePx,
}: RatingBarChartRowProps) => {
  const position = `STAR_${ratingPosition}` as Position
  const countByRatingPosition = starsStats[position]
  const percentage = calculateRatingRatioPercentage(
    countByRatingPosition,
    reviewsCount
  )
  const totalRatings = totalRatingsTemplate(countByRatingPosition, percentage)
  const columnWidthREM = ratingsColumnWidthSizePx / 16
  const inlineCSSVariableStyle = {} as { [key: string]: string }
  inlineCSSVariableStyle["--total-ratings-column-size"] = `${columnWidthREM}rem`

  return (
    <div
      className="overall-reviews-chart__rating-container"
      style={inlineCSSVariableStyle}
    >
      <div>{ratingPosition}</div>
      <div className="overall-reviews-chart__rating-bar-container">
        <div
          className="overall-reviews-chart__rating-bar"
          style={{ width: percentage + "%" }}
        ></div>
      </div>
      <div>{totalRatings}</div>
    </div>
  )
}
